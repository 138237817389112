import { NavLink } from "react-router-dom";
import { useState } from "react";
import Dropdown from "./Dropdown";

import Glitch from "./Glitch";

import DadosPage from "../pages/DadosPage";

function NavBar() {
  const [showDrop, setShowDrop] = useState(false);

  return (
    <div>
      <nav className="absolute left-0 right-0 top-0 bg-black px-6 py-6 z-40 flex items-center justify-between flex-none h-8">
        <NavLink
          key="indice"
          to="/indice"
          id="indice"
          onClick={() => {
            setShowDrop(false);
          }}
        >

          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 26 20">
            <g>
              <path className="st0" d="M12.9,0.1H1.1c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h11.8V0.1z" fill="#F9A51B"/>
              <path className="st0" d="M12.9,9.1H1.1c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h11.8V9.1z" fill="#F9A51B"/>
              <path className="st0" d="M12.9,18.1H1.1c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h11.8V18.1z" fill="#F9A51B"/>
            </g>
            <g className="move-menu">
              <path className="st0" d="M24.8,0.1H12.9v2h11.9c0.6,0,1-0.4,1-1C25.8,0.6,25.4,0.1,24.8,0.1z" fill="#F9A51B"/>
              <path className="st0" d="M24.8,9.1H12.9v2h11.9c0.6,0,1-0.4,1-1C25.8,9.6,25.4,9.1,24.8,9.1z" fill="#F9A51B"/>
              <path className="st0" d="M24.8,18.1H12.9v2h11.9c0.6,0,1-0.4,1-1C25.8,18.6,25.4,18.1,24.8,18.1z" fill="#F9A51B"/>
            </g>
          </svg>
        </NavLink>
        <Glitch>
          <NavLink
            key="home"
            to="/"
            id="logo"
            onClick={() => {
              setShowDrop(false);
            }}
          >
          <svg width="115" height="16" viewBox="0 0 115 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5503 16C6.14596 16 4.86785 15.7002 3.71598 15.1006C2.57988 14.501 1.67258 13.6174 0.994083 12.4497C0.331361 11.2663 0 9.8146 0 8.09467C0 6.39053 0.33925 4.93886 1.01775 3.73964C1.71203 2.52465 2.62722 1.60158 3.76331 0.970414C4.89941 0.323471 6.15385 0 7.52663 0C8.64694 0 9.60947 0.205128 10.4142 0.615385C11.2189 1.02564 11.8738 1.49112 12.3787 2.01183L10.5562 4.23669C10.1617 3.88955 9.74359 3.59763 9.30177 3.36095C8.85996 3.12426 8.30769 3.00592 7.64497 3.00592C6.44576 3.00592 5.46746 3.44773 4.71006 4.33136C3.95266 5.21499 3.57396 6.42998 3.57396 7.97633C3.57396 9.55424 3.93688 10.785 4.66272 11.6686C5.38856 12.5523 6.46943 12.9941 7.90532 12.9941C8.22091 12.9941 8.5286 12.9546 8.8284 12.8757C9.12821 12.7968 9.37278 12.6785 9.56213 12.5207V9.86982H7.00592V7.02959H12.6391V14.1302C12.1026 14.6509 11.3846 15.0927 10.4852 15.4556C9.60158 15.8185 8.62327 16 7.5503 16Z" fill="#F9A51A"/>
            <path d="M22.0993 16C20.7107 16 19.4879 15.6765 18.4307 15.0296C17.3892 14.3826 16.5687 13.4596 15.9691 12.2604C15.3853 11.0454 15.0934 9.60158 15.0934 7.92899C15.0934 6.24063 15.3853 4.81262 15.9691 3.64497C16.5687 2.46154 17.3892 1.56213 18.4307 0.946746C19.4879 0.315582 20.7107 0 22.0993 0C23.4879 0 24.7028 0.315582 25.7443 0.946746C26.8015 1.56213 27.622 2.46154 28.2058 3.64497C28.8054 4.8284 29.1052 6.25641 29.1052 7.92899C29.1052 9.60158 28.8054 11.0454 28.2058 12.2604C27.622 13.4596 26.8015 14.3826 25.7443 15.0296C24.7028 15.6765 23.4879 16 22.0993 16ZM22.0993 12.9941C23.1565 12.9941 23.9928 12.5365 24.6082 11.6213C25.2236 10.7061 25.5312 9.47535 25.5312 7.92899C25.5312 6.38264 25.2236 5.17554 24.6082 4.30769C23.9928 3.43984 23.1565 3.00592 22.0993 3.00592C21.0421 3.00592 20.2058 3.43984 19.5904 4.30769C18.975 5.17554 18.6673 6.38264 18.6673 7.92899C18.6673 9.47535 18.975 10.7061 19.5904 11.6213C20.2058 12.5365 21.0421 12.9941 22.0993 12.9941Z" fill="#F9A51A"/>
            <path d="M32.0068 15.716V0.284024H35.4861V12.7811H41.5926V15.716H32.0068Z" fill="#F9A51A"/>
            <path d="M44.2572 15.716V0.284024H49.559C50.6951 0.284024 51.7207 0.441815 52.6359 0.757396C53.5669 1.0572 54.3085 1.57002 54.8608 2.29586C55.413 3.0217 55.6892 4.00789 55.6892 5.25444C55.6892 6.45365 55.413 7.43984 54.8608 8.21302C54.3085 8.98619 53.5748 9.56213 52.6596 9.94083C51.7444 10.3037 50.7424 10.4852 49.6537 10.4852H47.7365V15.716H44.2572ZM47.7365 7.71597H49.4406C51.3341 7.71597 52.2809 6.89546 52.2809 5.25444C52.2809 4.4497 52.0284 3.88166 51.5235 3.5503C51.0186 3.21894 50.2927 3.05325 49.346 3.05325H47.7365V7.71597Z" fill="#F9A51A"/>
            <path d="M58.3567 15.716V0.284024H68.0135V3.21894H61.836V6.31953H67.0904V9.23077H61.836V12.7811H68.2502V15.716H58.3567Z" fill="#F9A51A"/>
            <path d="M71.3236 15.716V0.284024H81.0514V3.21894H74.8029V6.7929H80.152V9.72781H74.8029V15.716H71.3236Z" fill="#F9A51A"/>
            <path d="M83.7358 15.716V0.284024H87.215V12.7811H93.3216V15.716H83.7358Z" fill="#F9A51A"/>
            <path d="M95.9861 15.716V0.284024H99.4654V15.716H95.9861Z" fill="#F9A51A"/>
            <path d="M101.543 15.716L105.827 7.78698L101.803 0.284024H105.685L107.034 3.19527C107.208 3.54241 107.381 3.9211 107.555 4.33136C107.744 4.74162 107.941 5.19132 108.147 5.68047H108.241C108.415 5.19132 108.581 4.74162 108.738 4.33136C108.912 3.9211 109.078 3.54241 109.235 3.19527L110.466 0.284024H114.182L110.182 7.95266L114.442 15.716H110.561L109.022 12.568C108.833 12.1578 108.644 11.7554 108.454 11.3609C108.281 10.9507 108.084 10.5089 107.863 10.0355H107.768C107.594 10.5089 107.421 10.9507 107.247 11.3609C107.074 11.7554 106.9 12.1578 106.727 12.568L105.283 15.716H101.543Z" fill="#F9A51A"/>
          </svg>
          </NavLink>
        </Glitch>
        <div
          /*className={`show-dados-button cursor-pointer ${showDrop ? "active" : ""}`}
          onClick={() => {
            setShowDrop(!showDrop);
          }}*/
        >
        </div>
      </nav>
      <Dropdown
        inVisible={showDrop}
        onClose={() => {
          setShowDrop(false);
        }}
      >
        <DadosPage />
      </Dropdown>
    </div>
  );
}

export default NavBar;
