import { useState, useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import Glitch from "../components/Glitch";
import bg from "../assets/img/intro-video.mp4";
import { motion } from "framer-motion";
import { ShareButton } from "../components/Share";
import { toggleVolume } from '../stores/player'
import { useSelector, useDispatch } from 'react-redux'


function IntroPage() {
  const history = useNavigate();
  const volume = useSelector((state) => state.player.volume);
  const dispatch = useDispatch();
  const [CountSlides, setCountSlides] = useState(0);
  const mainplayerRef = useRef(null);

  const togglePlayMainPlayer = (p)=>{
    if(mainplayerRef.current){
      p ? mainplayerRef.current.play() : mainplayerRef.current.pause();
    }
  };

  const toggleMute = () => { 
    dispatch(toggleVolume(!volume));
  };

  const sliders = [
    {
      titulo: "",
      texto: "",
    },
    {
      titulo: "Para ninguém esquecer",
      texto:
        "A revolução nunca foi televisionada, mas o golpe passou no streaming. <i>GOLPEFLIX</i> é uma amostra histórica do conteúdo que circula todos os dias nos aplicativos de uma parcela radicalizada de brasileiros que se entendem patriotas, mas que, na verdade, atuam como agentes de conspirações promovidas por chefes de Estado, veículos de propaganda, influência estrangeira e algoritmos enviesados. Integram 687 mídias selecionadas a partir de uma base de 119 mil imagens, 25 mil áudios e 321 vídeos distribuídos em redes sociais e aplicativos de conversa monitorados pelo <strong>Aos Fatos</strong>.",
    },
    {
      titulo: "",
      texto:
        "Elas podem ser encontradas na rede social mais próxima de você, mas o <i>GOLPEFLIX</i> está aqui para lembrar que essas plataformas reúnem tais conteúdos porque eles são feitos sob medida para seu modelo de negócios. Ferramentas de monetização incentivam discursos apelativos, campanhas de engajamento usam a indignação seletiva como caixa registradora. Nas redes, a democracia é usada como alavancagem financeira sem qualquer parâmetro.",
    },
    {
      titulo: "",
      texto:
        "<i>GOLPEFLIX</i> narra a violência crescente do discurso bolsonarista, de natureza simbiótica com a paranoia plantada por um projeto militarista e autoritário contra a democracia brasileira. Essas mentiras não foram embora. Elas ainda reverberam no <i>GOLPEFLIX</i> da vida real: as redes que ainda alimentam e financiam o extremismo. <strong>Aos Fatos</strong> mostra as mentiras que os golpistas contaram e continuam repetindo.",
    },
  ];

  return (
      <div className="flex-auto relative flex flex-col justify-between">
        <div className="w-full h-full absolute overflow-hidden">
          <div className="video-wrapper intro h-menu">
            <video className="h-menu" webkit-playInline playsInline autoPlay={true} loop={true} ref={mainplayerRef}
              poster={bg}
              src={bg}
              muted={volume?false:true}
            ></video>
          </div>
        <div className="noise"></div>
        <div className="yellow-filter"></div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          transition={{ duration: 1, ease: "easeIn" }}
          exit={{ opacity: 0.5 }}
          className="overlay"
        ></motion.div>
      </div>
      <div
        className="flex-auto relative flex flex-col justify-between">
        <div className="p-6 relative z-40">
          <div className="flex justify-between items-end">
            <NavLink to="/" className="mb-6 flex">
              <Glitch>
                <svg width="115" height="16" viewBox="0 0 115 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5503 16C6.14596 16 4.86785 15.7002 3.71598 15.1006C2.57988 14.501 1.67258 13.6174 0.994083 12.4497C0.331361 11.2663 0 9.8146 0 8.09467C0 6.39053 0.33925 4.93886 1.01775 3.73964C1.71203 2.52465 2.62722 1.60158 3.76331 0.970414C4.89941 0.323471 6.15385 0 7.52663 0C8.64694 0 9.60947 0.205128 10.4142 0.615385C11.2189 1.02564 11.8738 1.49112 12.3787 2.01183L10.5562 4.23669C10.1617 3.88955 9.74359 3.59763 9.30177 3.36095C8.85996 3.12426 8.30769 3.00592 7.64497 3.00592C6.44576 3.00592 5.46746 3.44773 4.71006 4.33136C3.95266 5.21499 3.57396 6.42998 3.57396 7.97633C3.57396 9.55424 3.93688 10.785 4.66272 11.6686C5.38856 12.5523 6.46943 12.9941 7.90532 12.9941C8.22091 12.9941 8.5286 12.9546 8.8284 12.8757C9.12821 12.7968 9.37278 12.6785 9.56213 12.5207V9.86982H7.00592V7.02959H12.6391V14.1302C12.1026 14.6509 11.3846 15.0927 10.4852 15.4556C9.60158 15.8185 8.62327 16 7.5503 16Z" fill="#F9A51A"/>
                  <path d="M22.0993 16C20.7107 16 19.4879 15.6765 18.4307 15.0296C17.3892 14.3826 16.5687 13.4596 15.9691 12.2604C15.3853 11.0454 15.0934 9.60158 15.0934 7.92899C15.0934 6.24063 15.3853 4.81262 15.9691 3.64497C16.5687 2.46154 17.3892 1.56213 18.4307 0.946746C19.4879 0.315582 20.7107 0 22.0993 0C23.4879 0 24.7028 0.315582 25.7443 0.946746C26.8015 1.56213 27.622 2.46154 28.2058 3.64497C28.8054 4.8284 29.1052 6.25641 29.1052 7.92899C29.1052 9.60158 28.8054 11.0454 28.2058 12.2604C27.622 13.4596 26.8015 14.3826 25.7443 15.0296C24.7028 15.6765 23.4879 16 22.0993 16ZM22.0993 12.9941C23.1565 12.9941 23.9928 12.5365 24.6082 11.6213C25.2236 10.7061 25.5312 9.47535 25.5312 7.92899C25.5312 6.38264 25.2236 5.17554 24.6082 4.30769C23.9928 3.43984 23.1565 3.00592 22.0993 3.00592C21.0421 3.00592 20.2058 3.43984 19.5904 4.30769C18.975 5.17554 18.6673 6.38264 18.6673 7.92899C18.6673 9.47535 18.975 10.7061 19.5904 11.6213C20.2058 12.5365 21.0421 12.9941 22.0993 12.9941Z" fill="#F9A51A"/>
                  <path d="M32.0068 15.716V0.284024H35.4861V12.7811H41.5926V15.716H32.0068Z" fill="#F9A51A"/>
                  <path d="M44.2572 15.716V0.284024H49.559C50.6951 0.284024 51.7207 0.441815 52.6359 0.757396C53.5669 1.0572 54.3085 1.57002 54.8608 2.29586C55.413 3.0217 55.6892 4.00789 55.6892 5.25444C55.6892 6.45365 55.413 7.43984 54.8608 8.21302C54.3085 8.98619 53.5748 9.56213 52.6596 9.94083C51.7444 10.3037 50.7424 10.4852 49.6537 10.4852H47.7365V15.716H44.2572ZM47.7365 7.71597H49.4406C51.3341 7.71597 52.2809 6.89546 52.2809 5.25444C52.2809 4.4497 52.0284 3.88166 51.5235 3.5503C51.0186 3.21894 50.2927 3.05325 49.346 3.05325H47.7365V7.71597Z" fill="#F9A51A"/>
                  <path d="M58.3567 15.716V0.284024H68.0135V3.21894H61.836V6.31953H67.0904V9.23077H61.836V12.7811H68.2502V15.716H58.3567Z" fill="#F9A51A"/>
                  <path d="M71.3236 15.716V0.284024H81.0514V3.21894H74.8029V6.7929H80.152V9.72781H74.8029V15.716H71.3236Z" fill="#F9A51A"/>
                  <path d="M83.7358 15.716V0.284024H87.215V12.7811H93.3216V15.716H83.7358Z" fill="#F9A51A"/>
                  <path d="M95.9861 15.716V0.284024H99.4654V15.716H95.9861Z" fill="#F9A51A"/>
                  <path d="M101.543 15.716L105.827 7.78698L101.803 0.284024H105.685L107.034 3.19527C107.208 3.54241 107.381 3.9211 107.555 4.33136C107.744 4.74162 107.941 5.19132 108.147 5.68047H108.241C108.415 5.19132 108.581 4.74162 108.738 4.33136C108.912 3.9211 109.078 3.54241 109.235 3.19527L110.466 0.284024H114.182L110.182 7.95266L114.442 15.716H110.561L109.022 12.568C108.833 12.1578 108.644 11.7554 108.454 11.3609C108.281 10.9507 108.084 10.5089 107.863 10.0355H107.768C107.594 10.5089 107.421 10.9507 107.247 11.3609C107.074 11.7554 106.9 12.1578 106.727 12.568L105.283 15.716H101.543Z" fill="#F9A51A"/>
                </svg>
              </Glitch>
            </NavLink>
            <NavLink to="/indice" className="pb-6 pl-6 flex">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.55882 0.801961C8.14706 0.488235 8.85294 0.488235 9.44118 0.801961L15.9412 4.26863C16.5929 4.61621 17 5.29471 17 6.03333V13.4333C17 14.5379 16.1046 15.4333 15 15.4333H9.5V9.93333H7.5V15.4333H2C0.895431 15.4333 0 14.5379 0 13.4333V6.03333C0 5.29471 0.407096 4.61622 1.05882 4.26863L7.55882 0.801961ZM8.5 2.56667L2 6.03333V13.4333H5.5V9.93333C5.5 8.82876 6.39543 7.93333 7.5 7.93333H9.5C10.6046 7.93333 11.5 8.82876 11.5 9.93333V13.4333H15V6.03333L8.5 2.56667Z" fill="white"/>
              </svg>
            </NavLink>
          </div>
          <ul className="slider-bullets relative">
          {sliders.map((x, i) => {
            return (
              <li key={i}>
                <span style={{ width: i === CountSlides ? "100%" : "0%" }}>
                  {i + 1}
                </span>
              </li>
            );
          })}
        </ul>
        </div>

        <div className="controlslide next z-30"
          onClick={() => {
            if (CountSlides < sliders.length - 1) {
              setCountSlides(CountSlides + 1);
            } else {
              history("/indice");
            }
          }}
        ></div>
        <div className="controlslide prev z-30"
          onClick={() => {
            if (CountSlides > 0) {
              setCountSlides(CountSlides - 1);
            } else {
              history("/");
            }
          }}
        ></div>

        {[sliders[CountSlides]].map((x, i) => {
          return (
            <motion.div
              key={CountSlides}
              className="relative"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, ease: "easeIn" }}
              exit={{ opacity: 1 }}
            >
              <div className="p-6 relative z-20">
                <h2 dangerouslySetInnerHTML={{ __html: x.titulo }}></h2>
                <p dangerouslySetInnerHTML={{ __html: x.texto }} className="font-destaque text-base pre-line"></p>
              </div>
            </motion.div>
          );
        })}
      </div>
      <ShareButton capitulo={true}></ShareButton>
      <button className={`z-40 mute-button ${volume ? "" : "muted"}`} onClick={toggleMute}></button>
    </div>
  );
}

export default IntroPage;
