import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  show: false,
}

export const shareSlice = createSlice({
  name: 'share',
  initialState,
  reducers: {
    changeShow: (state,n) => {
      state.show = n.payload;
    },
  },
})

export const { changeShow } = shareSlice.actions
export default shareSlice.reducer