import React from "react";
import { useState } from "react";

import graficoTwitter from "../assets/img/graficos/grafico-twitter.svg";
import graficoWhatsapp from "../assets/img/graficos/grafico-whatsapp.svg";
import graficoFacebook from "../assets/img/graficos/grafico-facebook.svg";
import graficoInstagram from "../assets/img/graficos/grafico-instagram.svg";
import graficoYoutube from "../assets/img/graficos/grafico-youtube.svg";

function Graficos() {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <button id="dadosBtn" onClick={handleOpenModal}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="black"/>
        <path d="M13.5 15.5H9.83333C9.36662 15.5 9.13327 15.5 8.95501 15.5908C8.79821 15.6707 8.67072 15.7982 8.59083 15.955C8.5 16.1333 8.5 16.3666 8.5 16.8333V21.6667C8.5 22.1334 8.5 22.3667 8.59083 22.545C8.67072 22.7018 8.79821 22.8293 8.95501 22.9092C9.13327 23 9.36662 23 9.83333 23H13.5M13.5 23H18.5M13.5 23L13.5 12.6667C13.5 12.2 13.5 11.9666 13.5908 11.7883C13.6707 11.6315 13.7982 11.5041 13.955 11.4242C14.1333 11.3333 14.3666 11.3333 14.8333 11.3333H17.1667C17.6334 11.3333 17.8667 11.3333 18.045 11.4242C18.2018 11.5041 18.3293 11.6315 18.4092 11.7883C18.5 11.9666 18.5 12.2 18.5 12.6667V23M18.5 23H22.1667C22.6334 23 22.8667 23 23.045 22.9092C23.2018 22.8293 23.3293 22.7018 23.4092 22.545C23.5 22.3667 23.5 22.1334 23.5 21.6667V9.33333C23.5 8.86662 23.5 8.63327 23.4092 8.45501C23.3293 8.29821 23.2018 8.17072 23.045 8.09083C22.8667 8 22.6334 8 22.1667 8H19.8333C19.3666 8 19.1333 8 18.955 8.09083C18.7982 8.17072 18.6707 8.29821 18.5908 8.45501C18.5 8.63327 18.5 8.86662 18.5 9.33333V12.1667" stroke="#F9A51A" stroke-width="1.77778" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      {showModal && (
        <div
        className="absolute h-menu inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex  flex-col z-50"
        onClick={handleCloseModal} id="wrapper">
          <button className="place-self-end fixed top-0 right-0 mt-4 z-30">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="1" y="1" width="30" height="30" rx="15" fill="black" />
              <path
                d="M22 10L10 22M10 10L22 22"
                stroke="#F9A51A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect
                x="1"
                y="1"
                width="30"
                height="30"
                rx="15"
                stroke="#F9A51A"
                strokeWidth="2"
              />
            </svg>
          </button>
          <div className="flex flex-col bg-grey p-4 rounded-md mx-auto relative h-menu overflow-auto">
            <div className="flex flex-col w-full mb-6 md:mb-2 pt-14">
              <h2 className="mb-1.5">Golpismo em alta</h2>
              <p className="text-base font-destaque">Conteúdo antidemocrático nas redes disparou a partir de junho de 2022</p>

              <div className="mb-5 mt-5">
                <p className="text-xl mb-4">Twitter</p>
                <img className="w-full" src={graficoTwitter} />
              </div>

              <div className="mb-5 mt-5">
                <p className="text-xl mb-4">Whatsapp</p>
                <img className="w-full" src={graficoWhatsapp} />
              </div>

              <div className="mb-5 mt-5">
                <p className="text-xl mb-4">Facebook</p>
                <img className="w-full" src={graficoFacebook} />
              </div>

              <div className="mb-5 mt-5">
                <p className="text-xl mb-4">Instagram</p>
                <img className="w-full" src={graficoInstagram} />
              </div>

              <div className="mb-5 mt-5">
                <p className="text-xl mb-4">Youtube</p>
                <img className="w-full" src={graficoYoutube} />
              </div>

              <p className="text-base mt-6">Fonte: Radar Aos Fatos</p>
            </div>
          </div>
        </div>
        
      )}


    </>
  );
}

export default Graficos;
