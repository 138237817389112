import { useParams, useNavigate, NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { useState,useRef, useEffect } from "react";
import Glitch from "../components/Glitch";
import Background from "../assets/img/blsnr-capa.gif";

import { useSelector, useDispatch } from 'react-redux'
import { toggleVolume } from '../stores/player'
import {ShareButton} from "../components/Share";
import Graficos from "../components/Graficos";

import data from "../data.json";
import touch from "../assets/img/touch.png";

import audioModal from "../assets/img/audio-play.svg";
import audioStop from "../assets/img/audio-stop.svg";

import videoIcon from "../assets/img/icon/icone-video.png";
import imageIcon from "../assets/img/icon/icone-img.png";
import audioIcon from "../assets/img/icon/icone-audio.png";


function SubCapituloPage() {
  const volume = useSelector((state) => state.player.volume);
  const dispatch = useDispatch();

  const history = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [PresentStart, setPresentStart] = useState();
  const [audioPlaying, setAudioPlaying] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  const mainplayerRef = useRef(null);
  const secondaryplayerRef = useRef(null);

  const { cap_id, sub_id } = useParams();
  const capituloData = data.find(x=>x.capitulo===cap_id);
  const proximoData = data.find(x=>x.capitulo===String(Number(cap_id)+1));
  const subCapituloData = capituloData ? capituloData.subcapitulo.find(x=>x.capitulo===sub_id) : false;
  const totalSubCapitulos = capituloData ? capituloData.subcapitulo.length : 0;

  const togglePlayMainPlayer = (p)=>{
    if(mainplayerRef.current){
      p ? mainplayerRef.current.play() : mainplayerRef.current.pause();
    }
    setAudioPlaying(!secondaryplayerRef.current || !secondaryplayerRef.current.paused);
  };
  const toggleSecondaryPlayer = (e)=>{
    if(secondaryplayerRef.current && !e.target.classList.contains('mute-button')){
      secondaryplayerRef.current.paused ? secondaryplayerRef.current.play() : secondaryplayerRef.current.pause();
      setAudioPlaying(!secondaryplayerRef.current.paused);
    }
  };
  const toggleMute = () => { 
    dispatch(toggleVolume(!volume));
  };

  if(subCapituloData){
    return (
      <motion.div
        className="flex-auto relative flex flex-col justify-between aqui-subcapitulodata"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeIn" }}
        exit={{ opacity: 1 }}
      >
        
        <div className={subCapituloData.video && cap_id !== '1' ? "video-capitulo" : 'w-full absolute top-0 shadow-top shadow-bottom bottom-0'}>

          {cap_id !== '1' ? (
            <div>
              <div className="controlslide next z-30"
                onClick={() => {
                  setPresentStart(0);
                  history(`/capitulo/${cap_id}/${Number(sub_id)+1}`);
                }}
              ></div>

              {/* se tem subcapitulo anterior conteudo, se não capa capitulo */}
              { `${Number(sub_id)}` > 1 ? (
                <div className="controlslide prev z-30"
                  onClick={() => {
                    setPresentStart(0);
                    history(`/capitulo/${cap_id}/${Number(sub_id)-1}`);
                  }}
                ></div>
              ) :
              <div className="controlslide prev z-20"
                onClick={() => {
                  history(`/capitulo/${cap_id}/`);
                }}
              ></div>
              }
            </div>
          ) : ''}

          {cap_id === '1' ? (
            <div>
              { `${Number(totalSubCapitulos)}` === `${Number(sub_id)}` ? (
                <div className="controlslide next z-30"
                  onClick={() => {
                    setPresentStart(0);
                    history(`/indice`);
                  }}
                ></div>
              ) :
                <div className="controlslide next z-30"
                onClick={() => {
                    setPresentStart(0);
                    history(`/capitulo/${cap_id}/${Number(sub_id)+1}`);
                  }}
                ></div>
              }

              { `${Number(sub_id)}` > 1 ? (
                <div className="controlslide prev z-30"
                  onClick={() => {
                    setPresentStart(0);
                    history(`/capitulo/${cap_id}/${Number(sub_id)-1}`);
                  }}
                ></div>
              ) :
              <div className="controlslide prev z-20"
                onClick={() => {
                  history(`/capitulo/${cap_id}/`);
                }}
              ></div>
              }
              
            </div>
          ) : ''}

          <div>
            {subCapituloData.video? (
              <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1, ease:"easeIn"}} exit={{opacity:.5}}>
                <div className={`video-wrapper ${cap_id === '1' ? "intro" : ""}`}>
                  <video webkit-playInline playsInline autoPlay={true} loop={true} ref={mainplayerRef}
                    poster={process.env.REACT_APP_CDN + subCapituloData.img}
                    src={process.env.REACT_APP_CDN + subCapituloData.video}
                    muted={volume?false:true}
                  ></video>
                  {cap_id === '1'? (
                    <div>
                      <div className="noise"></div>
                      <div className="yellow-filter"></div>
                    </div>
                  ) : ""}
                </div>
                <button className={`mute-button ${volume ? "" : "muted"}`} onClick={toggleMute}></button>
              </motion.div>
            ) : 
              <figure className="w-full absolute top-0 shadow-top bottom-0">
                <img src={process.env.REACT_APP_CDN + subCapituloData.img} alt="" className="w-full h-full object-cover" />
                {cap_id === '1'? (
                  <div>
                    <div className="noise"></div>
                    <div className="yellow-filter"></div>
                  </div>
                ) : ""}
                <motion.div initial={{opacity:0}} animate={{opacity:.5}} transition={{duration:1, ease:"easeIn"}} exit={{opacity:.5}} className="overlay"></motion.div>

              </figure>
            }
          </div>
        </div>

        <div className="p-6 relative z-30">
          <div className="flex justify-between items-end">

            <NavLink to="/" className="mb-2 flex">
              <Glitch>
                <svg width="115" height="16" viewBox="0 0 115 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5503 16C6.14596 16 4.86785 15.7002 3.71598 15.1006C2.57988 14.501 1.67258 13.6174 0.994083 12.4497C0.331361 11.2663 0 9.8146 0 8.09467C0 6.39053 0.33925 4.93886 1.01775 3.73964C1.71203 2.52465 2.62722 1.60158 3.76331 0.970414C4.89941 0.323471 6.15385 0 7.52663 0C8.64694 0 9.60947 0.205128 10.4142 0.615385C11.2189 1.02564 11.8738 1.49112 12.3787 2.01183L10.5562 4.23669C10.1617 3.88955 9.74359 3.59763 9.30177 3.36095C8.85996 3.12426 8.30769 3.00592 7.64497 3.00592C6.44576 3.00592 5.46746 3.44773 4.71006 4.33136C3.95266 5.21499 3.57396 6.42998 3.57396 7.97633C3.57396 9.55424 3.93688 10.785 4.66272 11.6686C5.38856 12.5523 6.46943 12.9941 7.90532 12.9941C8.22091 12.9941 8.5286 12.9546 8.8284 12.8757C9.12821 12.7968 9.37278 12.6785 9.56213 12.5207V9.86982H7.00592V7.02959H12.6391V14.1302C12.1026 14.6509 11.3846 15.0927 10.4852 15.4556C9.60158 15.8185 8.62327 16 7.5503 16Z" fill="#F9A51A"/>
                  <path d="M22.0993 16C20.7107 16 19.4879 15.6765 18.4307 15.0296C17.3892 14.3826 16.5687 13.4596 15.9691 12.2604C15.3853 11.0454 15.0934 9.60158 15.0934 7.92899C15.0934 6.24063 15.3853 4.81262 15.9691 3.64497C16.5687 2.46154 17.3892 1.56213 18.4307 0.946746C19.4879 0.315582 20.7107 0 22.0993 0C23.4879 0 24.7028 0.315582 25.7443 0.946746C26.8015 1.56213 27.622 2.46154 28.2058 3.64497C28.8054 4.8284 29.1052 6.25641 29.1052 7.92899C29.1052 9.60158 28.8054 11.0454 28.2058 12.2604C27.622 13.4596 26.8015 14.3826 25.7443 15.0296C24.7028 15.6765 23.4879 16 22.0993 16ZM22.0993 12.9941C23.1565 12.9941 23.9928 12.5365 24.6082 11.6213C25.2236 10.7061 25.5312 9.47535 25.5312 7.92899C25.5312 6.38264 25.2236 5.17554 24.6082 4.30769C23.9928 3.43984 23.1565 3.00592 22.0993 3.00592C21.0421 3.00592 20.2058 3.43984 19.5904 4.30769C18.975 5.17554 18.6673 6.38264 18.6673 7.92899C18.6673 9.47535 18.975 10.7061 19.5904 11.6213C20.2058 12.5365 21.0421 12.9941 22.0993 12.9941Z" fill="#F9A51A"/>
                  <path d="M32.0068 15.716V0.284024H35.4861V12.7811H41.5926V15.716H32.0068Z" fill="#F9A51A"/>
                  <path d="M44.2572 15.716V0.284024H49.559C50.6951 0.284024 51.7207 0.441815 52.6359 0.757396C53.5669 1.0572 54.3085 1.57002 54.8608 2.29586C55.413 3.0217 55.6892 4.00789 55.6892 5.25444C55.6892 6.45365 55.413 7.43984 54.8608 8.21302C54.3085 8.98619 53.5748 9.56213 52.6596 9.94083C51.7444 10.3037 50.7424 10.4852 49.6537 10.4852H47.7365V15.716H44.2572ZM47.7365 7.71597H49.4406C51.3341 7.71597 52.2809 6.89546 52.2809 5.25444C52.2809 4.4497 52.0284 3.88166 51.5235 3.5503C51.0186 3.21894 50.2927 3.05325 49.346 3.05325H47.7365V7.71597Z" fill="#F9A51A"/>
                  <path d="M58.3567 15.716V0.284024H68.0135V3.21894H61.836V6.31953H67.0904V9.23077H61.836V12.7811H68.2502V15.716H58.3567Z" fill="#F9A51A"/>
                  <path d="M71.3236 15.716V0.284024H81.0514V3.21894H74.8029V6.7929H80.152V9.72781H74.8029V15.716H71.3236Z" fill="#F9A51A"/>
                  <path d="M83.7358 15.716V0.284024H87.215V12.7811H93.3216V15.716H83.7358Z" fill="#F9A51A"/>
                  <path d="M95.9861 15.716V0.284024H99.4654V15.716H95.9861Z" fill="#F9A51A"/>
                  <path d="M101.543 15.716L105.827 7.78698L101.803 0.284024H105.685L107.034 3.19527C107.208 3.54241 107.381 3.9211 107.555 4.33136C107.744 4.74162 107.941 5.19132 108.147 5.68047H108.241C108.415 5.19132 108.581 4.74162 108.738 4.33136C108.912 3.9211 109.078 3.54241 109.235 3.19527L110.466 0.284024H114.182L110.182 7.95266L114.442 15.716H110.561L109.022 12.568C108.833 12.1578 108.644 11.7554 108.454 11.3609C108.281 10.9507 108.084 10.5089 107.863 10.0355H107.768C107.594 10.5089 107.421 10.9507 107.247 11.3609C107.074 11.7554 106.9 12.1578 106.727 12.568L105.283 15.716H101.543Z" fill="#F9A51A"/>
                </svg>
              </Glitch>
            </NavLink>
            <div className="flex items-end">
              <legend className="text-right leading-4">{subCapituloData.titulo}</legend>
              <NavLink to="/indice" className="pb-2 pl-3 flex">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.55882 0.801961C8.14706 0.488235 8.85294 0.488235 9.44118 0.801961L15.9412 4.26863C16.5929 4.61621 17 5.29471 17 6.03333V13.4333C17 14.5379 16.1046 15.4333 15 15.4333H9.5V9.93333H7.5V15.4333H2C0.895431 15.4333 0 14.5379 0 13.4333V6.03333C0 5.29471 0.407096 4.61622 1.05882 4.26863L7.55882 0.801961ZM8.5 2.56667L2 6.03333V13.4333H5.5V9.93333C5.5 8.82876 6.39543 7.93333 7.5 7.93333H9.5C10.6046 7.93333 11.5 8.82876 11.5 9.93333V13.4333H15V6.03333L8.5 2.56667Z" fill="white"/>
                </svg>
              </NavLink>
            </div>

          </div>

          <ul className="slider-bullets">
            <li><span style={{ width: '0%' }}></span></li>
            {capituloData ? capituloData.subcapitulo.map((x,i)=>{
              return (<li key={i}><span style={{ width: x.capitulo===sub_id?"100%":'0%' }}>{i+1}</span></li>);
            }) : ''}
          </ul>
        </div>

        <div className="z-20">
          {subCapituloData.intro ? (
            <p className="p-6 absolute z-20 bottom-0 text-base font-destaque">{subCapituloData.intro}</p>
          ) : ''}
          {subCapituloData.media ? (
            <div className="thumbnails z-30">
              {subCapituloData.media.map((x,i)=>{
                return (
                  <div
                    key={'th'+i}
                    onClick={() => {
                      setCurrentIndex(i);
                      setShowModal(x);
                      togglePlayMainPlayer(false);
                    }}
                    className={`thumbnail ${x.tipo}`}
                  >
                    {x.thumbnail ? (
                    <img src={process.env.REACT_APP_CDN + x.thumbnail} alt={x.autor} />
                    ) : ''}
                    
                      <div className="thumbnail-icon">
                        <Glitch Glitchtype="icons">
                          {x.tipo === 'video'? (
                              <img src={videoIcon}/>
                          ) : ''}
                          {x.tipo === 'audio'? (
                              <img src={audioIcon}/>
                              ) : ''}
                          {x.tipo === 'imagem'? (
                              <img src={imageIcon}/>
                              ) : ''}
                        </Glitch>
                      </div>

                    
                  </div>
                );
              })}
            </div>
          ) : ''}

        </div>

        {showModal !== false ? (
          <div
            className="absolute z-50 inset-0 bg-black bg-opacity-80 backdrop-blur-sm flex  flex-col p-6 items-center h-full"
            onClick={(e)=>{ 
              if (e.target.id === "wrapper"){
                setShowModal(false);togglePlayMainPlayer(true); 
              }
            }}
            id="wrapper"
          >
            <div className="place-self-end mb-4" onClick={()=>{ setShowModal(false);togglePlayMainPlayer(true); }}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="30" height="30" rx="15" fill="black" />
                <path d="M22 10L10 22M10 10L22 22" stroke="#FF8D54" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="1" y="1" width="30" height="30" rx="15" stroke="#FF8D54" strokeWidth="2"/>
              </svg>
            </div>
            <div className="flex flex-col justify-center items-center w-full">
            
              <div className="flex flex-col items-center mb-8 md:mb-2 w-full">
                <div className="mobilearea bg-black">
                  
                  {subCapituloData.media[currentIndex].tipo === 'video' && (
                    <div className="w-full bg-black flex flex-col items-center justify-center">
                      <video className="video-modal" webkit-playsInline playsInline autoPlay={true} loop={true}
                        ref={secondaryplayerRef}
                        poster={subCapituloData.media[currentIndex].thumbnail ? process.env.REACT_APP_CDN + subCapituloData.media[currentIndex].thumbnail : ''} 
                        src={process.env.REACT_APP_CDN + subCapituloData.media[currentIndex].arquivo} 
                        muted={volume?false:true}
                        onClick={toggleSecondaryPlayer}
                      ></video>
                      <button className={`mute-button ${volume ? "" : "muted"}`} onClick={toggleMute}></button>
                    </div>
                  )}
                  {subCapituloData.media[currentIndex].tipo === 'audio' && (
                    <div className="w-full bg-black flex flex-col items-center justify-center" onClick={toggleSecondaryPlayer}>
                      { subCapituloData.media[currentIndex].thumbnail ? (
                        <img className="img-modal" src={process.env.REACT_APP_CDN + subCapituloData.media[currentIndex].thumbnail} alt={subCapituloData.media[currentIndex].fonte+' '+subCapituloData.media[currentIndex].autor} />
                      ) : ''}
                      <img className="audio-modal" src={audioPlaying?audioModal:audioStop} alt="Audio"/>
                      <audio autoPlay={true} controls={true} preload="metadata"
                        className="hidden"
                        src={process.env.REACT_APP_CDN + subCapituloData.media[currentIndex].arquivo} 
                        title={showModal.fonte+' '+showModal.autor}
                        ref={secondaryplayerRef}
                        ></audio>
                    </div>
                  )}
                  {subCapituloData.media[currentIndex].tipo === 'imagem' && (
                    <img className="img-modal" src={process.env.REACT_APP_CDN + subCapituloData.media[currentIndex].arquivo} alt={showModal.fonte+' '+showModal.autor} />
                  )}
                </div>
                <legend>Fonte: {subCapituloData.media[currentIndex].autor} - {subCapituloData.media[currentIndex].fonte}</legend>

                <button className="next-thumb" onClick={() => setCurrentIndex((currentIndex + 1) % subCapituloData.media.length)}>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="30" height="30" rx="15" fill="black"/>
                  <path d="M13.5 9L20.5 16L13.5 23" stroke="#5C7BE7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <rect x="1" y="1" width="30" height="30" rx="15" stroke="#5C7BE7" stroke-width="2"/>
                  </svg>
                </button>

                <button className="prev-thumb" onClick={() => setCurrentIndex((currentIndex - 1 + subCapituloData.media.length) % subCapituloData.media.length)}>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="31" y="31" width="30" height="30" rx="15" transform="rotate(-180 31 31)" fill="black"/>
                  <path d="M18.5 23L11.5 16L18.5 9" stroke="#5C7BE7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <rect x="31" y="31" width="30" height="30" rx="15" transform="rotate(-180 31 31)" stroke="#5C7BE7" stroke-width="2"/>
                  </svg>
                </button>

              </div>

              <div className="flex flex-wrap justify-center">
                {showModal?.tags ? showModal.tags.map(x=>{
                  return (
                    <div className="tag">#{x}</div>
                  );
                }) : ''}
              </div>

            </div>
          </div>
        ) : ''}

        <ShareButton capitulo={capituloData}></ShareButton>
        {cap_id === '9' ? (
          <Graficos/>
        ) : ''}

      </motion.div>
    );
  } else if(capituloData && proximoData){
    return (
      <motion.div
        className="flex-auto relative flex flex-col justify-between"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeIn" }}
        exit={{ opacity: 1 }}
      >
        <div className="flex-auto relative flex flex-col justify-between">
          <div className="w-full absolute top-0 shadow-top shadow-bottom bottom-0">
            <figure className="w-full absolute top-0 shadow-top shadow-bottom bottom-0">
              <img src={process.env.REACT_APP_CDN + capituloData.img} alt={capituloData.titulo} className="w-full h-full object-cover" />
              <motion.div initial={{opacity:0}} animate={{opacity:.5}} transition={{duration:1, ease:"easeIn"}} exit={{opacity:.5}} className="overlay"></motion.div>
            </figure>
          </div>

          <div className="pl-6 pr-6 pt-6 relative z-40">
            <div className="flex justify-between items-end">
              <NavLink to="/" className="mb-6 flex">
                <Glitch>
                  <svg width="115" height="16" viewBox="0 0 115 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5503 16C6.14596 16 4.86785 15.7002 3.71598 15.1006C2.57988 14.501 1.67258 13.6174 0.994083 12.4497C0.331361 11.2663 0 9.8146 0 8.09467C0 6.39053 0.33925 4.93886 1.01775 3.73964C1.71203 2.52465 2.62722 1.60158 3.76331 0.970414C4.89941 0.323471 6.15385 0 7.52663 0C8.64694 0 9.60947 0.205128 10.4142 0.615385C11.2189 1.02564 11.8738 1.49112 12.3787 2.01183L10.5562 4.23669C10.1617 3.88955 9.74359 3.59763 9.30177 3.36095C8.85996 3.12426 8.30769 3.00592 7.64497 3.00592C6.44576 3.00592 5.46746 3.44773 4.71006 4.33136C3.95266 5.21499 3.57396 6.42998 3.57396 7.97633C3.57396 9.55424 3.93688 10.785 4.66272 11.6686C5.38856 12.5523 6.46943 12.9941 7.90532 12.9941C8.22091 12.9941 8.5286 12.9546 8.8284 12.8757C9.12821 12.7968 9.37278 12.6785 9.56213 12.5207V9.86982H7.00592V7.02959H12.6391V14.1302C12.1026 14.6509 11.3846 15.0927 10.4852 15.4556C9.60158 15.8185 8.62327 16 7.5503 16Z" fill="#F9A51A"/>
                    <path d="M22.0993 16C20.7107 16 19.4879 15.6765 18.4307 15.0296C17.3892 14.3826 16.5687 13.4596 15.9691 12.2604C15.3853 11.0454 15.0934 9.60158 15.0934 7.92899C15.0934 6.24063 15.3853 4.81262 15.9691 3.64497C16.5687 2.46154 17.3892 1.56213 18.4307 0.946746C19.4879 0.315582 20.7107 0 22.0993 0C23.4879 0 24.7028 0.315582 25.7443 0.946746C26.8015 1.56213 27.622 2.46154 28.2058 3.64497C28.8054 4.8284 29.1052 6.25641 29.1052 7.92899C29.1052 9.60158 28.8054 11.0454 28.2058 12.2604C27.622 13.4596 26.8015 14.3826 25.7443 15.0296C24.7028 15.6765 23.4879 16 22.0993 16ZM22.0993 12.9941C23.1565 12.9941 23.9928 12.5365 24.6082 11.6213C25.2236 10.7061 25.5312 9.47535 25.5312 7.92899C25.5312 6.38264 25.2236 5.17554 24.6082 4.30769C23.9928 3.43984 23.1565 3.00592 22.0993 3.00592C21.0421 3.00592 20.2058 3.43984 19.5904 4.30769C18.975 5.17554 18.6673 6.38264 18.6673 7.92899C18.6673 9.47535 18.975 10.7061 19.5904 11.6213C20.2058 12.5365 21.0421 12.9941 22.0993 12.9941Z" fill="#F9A51A"/>
                    <path d="M32.0068 15.716V0.284024H35.4861V12.7811H41.5926V15.716H32.0068Z" fill="#F9A51A"/>
                    <path d="M44.2572 15.716V0.284024H49.559C50.6951 0.284024 51.7207 0.441815 52.6359 0.757396C53.5669 1.0572 54.3085 1.57002 54.8608 2.29586C55.413 3.0217 55.6892 4.00789 55.6892 5.25444C55.6892 6.45365 55.413 7.43984 54.8608 8.21302C54.3085 8.98619 53.5748 9.56213 52.6596 9.94083C51.7444 10.3037 50.7424 10.4852 49.6537 10.4852H47.7365V15.716H44.2572ZM47.7365 7.71597H49.4406C51.3341 7.71597 52.2809 6.89546 52.2809 5.25444C52.2809 4.4497 52.0284 3.88166 51.5235 3.5503C51.0186 3.21894 50.2927 3.05325 49.346 3.05325H47.7365V7.71597Z" fill="#F9A51A"/>
                    <path d="M58.3567 15.716V0.284024H68.0135V3.21894H61.836V6.31953H67.0904V9.23077H61.836V12.7811H68.2502V15.716H58.3567Z" fill="#F9A51A"/>
                    <path d="M71.3236 15.716V0.284024H81.0514V3.21894H74.8029V6.7929H80.152V9.72781H74.8029V15.716H71.3236Z" fill="#F9A51A"/>
                    <path d="M83.7358 15.716V0.284024H87.215V12.7811H93.3216V15.716H83.7358Z" fill="#F9A51A"/>
                    <path d="M95.9861 15.716V0.284024H99.4654V15.716H95.9861Z" fill="#F9A51A"/>
                    <path d="M101.543 15.716L105.827 7.78698L101.803 0.284024H105.685L107.034 3.19527C107.208 3.54241 107.381 3.9211 107.555 4.33136C107.744 4.74162 107.941 5.19132 108.147 5.68047H108.241C108.415 5.19132 108.581 4.74162 108.738 4.33136C108.912 3.9211 109.078 3.54241 109.235 3.19527L110.466 0.284024H114.182L110.182 7.95266L114.442 15.716H110.561L109.022 12.568C108.833 12.1578 108.644 11.7554 108.454 11.3609C108.281 10.9507 108.084 10.5089 107.863 10.0355H107.768C107.594 10.5089 107.421 10.9507 107.247 11.3609C107.074 11.7554 106.9 12.1578 106.727 12.568L105.283 15.716H101.543Z" fill="#F9A51A"/>
                  </svg>
                </Glitch>
              </NavLink>
              <NavLink to="/indice" className="pb-6 pl-6 flex">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.55882 0.801961C8.14706 0.488235 8.85294 0.488235 9.44118 0.801961L15.9412 4.26863C16.5929 4.61621 17 5.29471 17 6.03333V13.4333C17 14.5379 16.1046 15.4333 15 15.4333H9.5V9.93333H7.5V15.4333H2C0.895431 15.4333 0 14.5379 0 13.4333V6.03333C0 5.29471 0.407096 4.61622 1.05882 4.26863L7.55882 0.801961ZM8.5 2.56667L2 6.03333V13.4333H5.5V9.93333C5.5 8.82876 6.39543 7.93333 7.5 7.93333H9.5C10.6046 7.93333 11.5 8.82876 11.5 9.93333V13.4333H15V6.03333L8.5 2.56667Z" fill="white"/>
                </svg>
              </NavLink>
            </div>
            <ul className="slider-bullets">
              {capituloData ? capituloData.subcapitulo.map((x,i)=>{
                return (<li key={i}><span style={{ width: "100%" }}>{i+1}</span></li>);
              }) : ''}
            </ul>
          </div>

          <div className="controlslide next z-30"
            onClick={() => {
              setPresentStart(0);
              history(`/capitulo/${proximoData.capitulo}`);
            }}
          ></div>
          <div className="controlslide prev z-30"
            onClick={() => {
              setPresentStart(0);
              history(`/capitulo/${cap_id}/${Number(sub_id)-1}`);
            }}
          ></div>

          <div className="p-6 flex justify-between items-end z-20">
            <div className="videoarea thumbnail-destaque">
              <figure className="thumbnail">
                <img className="thumbnail-fit" src={process.env.REACT_APP_CDN + proximoData.img} alt={proximoData.titulo} />
                <div className="noise"></div>
                <div className="yellow-filter"></div>
              </figure>
              <div className="infos">
                <p className="text-6xl m-0">{proximoData.capitulo}</p>
                <p className="m-0 text-2xl leading-6 font-bold">{proximoData.titulo}</p>
              </div>
            </div>
            <div className="touch-button">
              <img src={touch} alt="Próximo capitulo" />
            </div>
          </div>
        </div>
        <ShareButton capitulo={capituloData}></ShareButton>
      </motion.div>
    );
  } else if(capituloData && cap_id === '9'){
    return (
      <div className="flex-auto relative flex flex-col justify-end">
      <NavLink
        to={"/intro"}
        className="flex-auto relative flex flex-col justify-end"
      >
        <figure className="w-full h-full absolute left-0 top-0 overflow-hidden">
          <Glitch Glitchtype="full">
            <img src={Background} alt="Golpeflix" className="w-full h-full object-cover"/>
          </Glitch>
        </figure>

        <div className="p-8 relative flex items-center justify-center h-full w-full z-20">
          <div className="flex flex-col flex items-center justify-center">
            <Glitch>
              <div className="flex justify-center mb-4 mt-4">
                <svg
                  width="315"
                  height="44"
                  viewBox="0 0 315 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.7633 44C16.9014 44 13.3866 43.1755 10.2189 41.5266C7.09467 39.8777 4.59961 37.4477 2.73373 34.2367C0.911242 30.9822 0 26.9901 0 22.2604C0 17.574 0.932938 13.5819 2.79882 10.284C4.70809 6.9428 7.22485 4.40434 10.3491 2.66864C13.4734 0.889546 16.9231 0 20.6982 0C23.7791 0 26.426 0.564103 28.6391 1.69231C30.8521 2.82051 32.6529 4.10059 34.0414 5.53254L29.0296 11.6509C27.9448 10.6963 26.7949 9.89349 25.5799 9.2426C24.3649 8.59172 22.8462 8.26627 21.0237 8.26627C17.7258 8.26627 15.0355 9.48126 12.9527 11.9112C10.8698 14.3412 9.8284 17.6824 9.8284 21.9349C9.8284 26.2742 10.8264 29.6588 12.8225 32.0888C14.8185 34.5187 17.7909 35.7337 21.7396 35.7337C22.6075 35.7337 23.4536 35.6252 24.2781 35.4083C25.1026 35.1913 25.7751 34.8659 26.2959 34.432V27.142H19.2663V19.3314H34.7574V38.858C33.282 40.2899 31.3077 41.5049 28.8343 42.503C26.4043 43.501 23.714 44 20.7633 44Z"
                    fill="white"
                  />
                  <path
                    d="M60.7731 44C56.9545 44 53.5916 43.1105 50.6843 41.3314C47.8204 39.5523 45.564 37.0138 43.9151 33.716C42.3095 30.3748 41.5068 26.4043 41.5068 21.8047C41.5068 17.1617 42.3095 13.2347 43.9151 10.0237C45.564 6.76923 47.8204 4.29586 50.6843 2.60355C53.5916 0.867851 56.9545 0 60.7731 0C64.5916 0 67.9328 0.867851 70.7967 2.60355C73.704 4.29586 75.9604 6.76923 77.566 10.0237C79.2149 13.2781 80.0393 17.2051 80.0393 21.8047C80.0393 26.4043 79.2149 30.3748 77.566 33.716C75.9604 37.0138 73.704 39.5523 70.7967 41.3314C67.9328 43.1105 64.5916 44 60.7731 44ZM60.7731 35.7337C63.6804 35.7337 65.9802 34.4753 67.6725 31.9586C69.3648 29.4418 70.2109 26.0572 70.2109 21.8047C70.2109 17.5523 69.3648 14.2327 67.6725 11.8462C65.9802 9.45957 63.6804 8.26627 60.7731 8.26627C57.8658 8.26627 55.566 9.45957 53.8737 11.8462C52.1814 14.2327 51.3352 17.5523 51.3352 21.8047C51.3352 26.0572 52.1814 29.4418 53.8737 31.9586C55.566 34.4753 57.8658 35.7337 60.7731 35.7337Z"
                    fill="white"
                  />
                  <path
                    d="M88.0188 43.2189V0.781067H97.5869V35.1479H114.38V43.2189H88.0188Z"
                    fill="white"
                  />
                  <path
                    d="M121.707 43.2189V0.781067H136.287C139.411 0.781067 142.232 1.21499 144.749 2.08284C147.309 2.9073 149.348 4.31755 150.867 6.31361C152.386 8.30966 153.145 11.0217 153.145 14.4497C153.145 17.7475 152.386 20.4596 150.867 22.5858C149.348 24.712 147.331 26.2959 144.814 27.3373C142.297 28.3353 139.542 28.8343 136.548 28.8343H131.275V43.2189H121.707ZM131.275 21.2189H135.962C141.169 21.2189 143.772 18.9625 143.772 14.4497C143.772 12.2367 143.078 10.6746 141.69 9.76332C140.301 8.85207 138.305 8.39645 135.701 8.39645H131.275V21.2189Z"
                    fill="white"
                  />
                  <path
                    d="M160.481 43.2189V0.781067H187.037V8.85207H170.049V17.3787H184.499V25.3846H170.049V35.1479H187.688V43.2189H160.481Z"
                    fill="white"
                  />
                  <path
                    d="M196.14 43.2189V0.781067H222.891V8.85207H205.708V18.6805H220.418V26.7515H205.708V43.2189H196.14Z"
                    fill="white"
                  />
                  <path
                    d="M230.273 43.2189V0.781067H239.841V35.1479H256.634V43.2189H230.273Z"
                    fill="white"
                  />
                  <path
                    d="M263.962 43.2189V0.781067H273.53V43.2189H263.962Z"
                    fill="white"
                  />
                  <path
                    d="M279.243 43.2189L291.025 21.4142L279.959 0.781067H290.634L294.344 8.78698C294.821 9.74162 295.299 10.783 295.776 11.9112C296.297 13.0394 296.839 14.2761 297.403 15.6213H297.664C298.141 14.2761 298.597 13.0394 299.03 11.9112C299.508 10.783 299.963 9.74162 300.397 8.78698L303.782 0.781067H314.001L303.001 21.8698L314.717 43.2189H304.042L299.812 34.5621C299.291 33.4339 298.77 32.3274 298.249 31.2426C297.772 30.1144 297.23 28.8994 296.622 27.5976H296.362C295.884 28.8994 295.407 30.1144 294.93 31.2426C294.453 32.3274 293.975 33.4339 293.498 34.5621L289.527 43.2189H279.243Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Glitch>
            <div className="font-destaque  text-center text-xl w-12/12 ">
              O catálogo digital das mentiras <br />que levaram ao 8 de janeiro
            </div>
          </div>
        </div>

        <div className=" p-8 relative flex w-full justify-center items-center text-sm z-20">
          <span className="text-white font-destaque flex items-center">
            Toque para avançar
            <div className="ml-2 touch-button">
              <img src={touch} alt="Toque para avançar" />
            </div>
          </span>
        </div>
      </NavLink>
      <ShareButton capitulo={true}></ShareButton>
    </div>
    );
  }
}

export default SubCapituloPage;


