import { NavLink } from "react-router-dom";
/* import { motion } from "framer-motion"; */

function ErrorPage() {
  return (
    <div className="">
      <h3>Error Page</h3>
      <NavLink to="/home" className="">
        Voltar
      </NavLink>
    </div>
  );
}

export default ErrorPage;
