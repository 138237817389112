import { NavLink } from "react-router-dom";
import Background from "../assets/img/blsnr-capa.gif";
import BgGli from "../assets/img/grafismos.gif";
import Glitch from "../components/Glitch";
import touch from "../assets/img/touch.png";
import {ShareButton} from "../components/Share";

function CapaPage() {
  return (
    <div className="flex-auto relative flex flex-col justify-end">
      <NavLink
        to={"/capitulo/1"}
        className="flex-auto relative flex flex-col justify-end"
      >
        <figure className="w-full h-full absolute left-0 top-0 overflow-hidden">
          <Glitch Glitchtype="full">
            <img src={Background} alt="Golpeflix" className="w-full h-full object-cover"/>
          </Glitch>
        </figure>
        {/* <figure className="w-full h-full absolute left-0 top-0 shadow-bottom">
          <img src={BgGli} alt="Glitch" className="absolute w-full h-full object-cover top-0 left-0" />
        </figure> */}

        <div className="p-8 relative flex items-center justify-center h-full w-full z-20">
          <div className="flex flex-col flex items-center justify-center">
            <Glitch>
              <div className="flex justify-center mb-4 mt-4">
                <svg
                  width="315"
                  height="44"
                  viewBox="0 0 315 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.7633 44C16.9014 44 13.3866 43.1755 10.2189 41.5266C7.09467 39.8777 4.59961 37.4477 2.73373 34.2367C0.911242 30.9822 0 26.9901 0 22.2604C0 17.574 0.932938 13.5819 2.79882 10.284C4.70809 6.9428 7.22485 4.40434 10.3491 2.66864C13.4734 0.889546 16.9231 0 20.6982 0C23.7791 0 26.426 0.564103 28.6391 1.69231C30.8521 2.82051 32.6529 4.10059 34.0414 5.53254L29.0296 11.6509C27.9448 10.6963 26.7949 9.89349 25.5799 9.2426C24.3649 8.59172 22.8462 8.26627 21.0237 8.26627C17.7258 8.26627 15.0355 9.48126 12.9527 11.9112C10.8698 14.3412 9.8284 17.6824 9.8284 21.9349C9.8284 26.2742 10.8264 29.6588 12.8225 32.0888C14.8185 34.5187 17.7909 35.7337 21.7396 35.7337C22.6075 35.7337 23.4536 35.6252 24.2781 35.4083C25.1026 35.1913 25.7751 34.8659 26.2959 34.432V27.142H19.2663V19.3314H34.7574V38.858C33.282 40.2899 31.3077 41.5049 28.8343 42.503C26.4043 43.501 23.714 44 20.7633 44Z"
                    fill="white"
                  />
                  <path
                    d="M60.7731 44C56.9545 44 53.5916 43.1105 50.6843 41.3314C47.8204 39.5523 45.564 37.0138 43.9151 33.716C42.3095 30.3748 41.5068 26.4043 41.5068 21.8047C41.5068 17.1617 42.3095 13.2347 43.9151 10.0237C45.564 6.76923 47.8204 4.29586 50.6843 2.60355C53.5916 0.867851 56.9545 0 60.7731 0C64.5916 0 67.9328 0.867851 70.7967 2.60355C73.704 4.29586 75.9604 6.76923 77.566 10.0237C79.2149 13.2781 80.0393 17.2051 80.0393 21.8047C80.0393 26.4043 79.2149 30.3748 77.566 33.716C75.9604 37.0138 73.704 39.5523 70.7967 41.3314C67.9328 43.1105 64.5916 44 60.7731 44ZM60.7731 35.7337C63.6804 35.7337 65.9802 34.4753 67.6725 31.9586C69.3648 29.4418 70.2109 26.0572 70.2109 21.8047C70.2109 17.5523 69.3648 14.2327 67.6725 11.8462C65.9802 9.45957 63.6804 8.26627 60.7731 8.26627C57.8658 8.26627 55.566 9.45957 53.8737 11.8462C52.1814 14.2327 51.3352 17.5523 51.3352 21.8047C51.3352 26.0572 52.1814 29.4418 53.8737 31.9586C55.566 34.4753 57.8658 35.7337 60.7731 35.7337Z"
                    fill="white"
                  />
                  <path
                    d="M88.0188 43.2189V0.781067H97.5869V35.1479H114.38V43.2189H88.0188Z"
                    fill="white"
                  />
                  <path
                    d="M121.707 43.2189V0.781067H136.287C139.411 0.781067 142.232 1.21499 144.749 2.08284C147.309 2.9073 149.348 4.31755 150.867 6.31361C152.386 8.30966 153.145 11.0217 153.145 14.4497C153.145 17.7475 152.386 20.4596 150.867 22.5858C149.348 24.712 147.331 26.2959 144.814 27.3373C142.297 28.3353 139.542 28.8343 136.548 28.8343H131.275V43.2189H121.707ZM131.275 21.2189H135.962C141.169 21.2189 143.772 18.9625 143.772 14.4497C143.772 12.2367 143.078 10.6746 141.69 9.76332C140.301 8.85207 138.305 8.39645 135.701 8.39645H131.275V21.2189Z"
                    fill="white"
                  />
                  <path
                    d="M160.481 43.2189V0.781067H187.037V8.85207H170.049V17.3787H184.499V25.3846H170.049V35.1479H187.688V43.2189H160.481Z"
                    fill="white"
                  />
                  <path
                    d="M196.14 43.2189V0.781067H222.891V8.85207H205.708V18.6805H220.418V26.7515H205.708V43.2189H196.14Z"
                    fill="white"
                  />
                  <path
                    d="M230.273 43.2189V0.781067H239.841V35.1479H256.634V43.2189H230.273Z"
                    fill="white"
                  />
                  <path
                    d="M263.962 43.2189V0.781067H273.53V43.2189H263.962Z"
                    fill="white"
                  />
                  <path
                    d="M279.243 43.2189L291.025 21.4142L279.959 0.781067H290.634L294.344 8.78698C294.821 9.74162 295.299 10.783 295.776 11.9112C296.297 13.0394 296.839 14.2761 297.403 15.6213H297.664C298.141 14.2761 298.597 13.0394 299.03 11.9112C299.508 10.783 299.963 9.74162 300.397 8.78698L303.782 0.781067H314.001L303.001 21.8698L314.717 43.2189H304.042L299.812 34.5621C299.291 33.4339 298.77 32.3274 298.249 31.2426C297.772 30.1144 297.23 28.8994 296.622 27.5976H296.362C295.884 28.8994 295.407 30.1144 294.93 31.2426C294.453 32.3274 293.975 33.4339 293.498 34.5621L289.527 43.2189H279.243Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Glitch>
            <div className="font-destaque  text-center text-xl w-12/12 ">
              O catálogo digital das mentiras <br />que levaram ao 8 de janeiro
            </div>
          </div>
        </div>

        <NavLink to={"/intro"} className=" p-8 relative flex w-full justify-center items-center text-sm">
          <div className="text-white font-destaque flex items-center">
            <span className="block md:hidden">Toque nas laterais para navegar</span>
            <span className="hidden md:block">Clique nas laterais para navegar</span>
            <div className="ml-2 touch-button">
              <img src={touch} alt="Toque nas laterais para navegar" />
            </div>
          </div>
        </NavLink>
      </NavLink>
      <ShareButton capitulo={true}></ShareButton>
    </div>
  );
}

export default CapaPage;
