import { configureStore } from '@reduxjs/toolkit';
import playerReducer from './stores/player';
import shareReducer from './stores/share';

export default configureStore({
  reducer: {
    player: playerReducer,
    share: shareReducer,
  }
});
