import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  volume: 1,
}

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    toggleVolume: (state) => {
      state.volume = !state.volume
    },
  },
})

export const { toggleVolume } = playerSlice.actions
export default playerSlice.reducer