import React from "react";
import { useGlitch } from "react-powerglitch";

const Glitch = ({Glitchtype, children}) => {
  const start = Math.random()*0.8;
  const glitch = useGlitch({
    "playMode": "always",
    "createContainers": true,
    "hideOverflow": false,
    "timing": {
      "duration": Math.round( 5000 + (Math.random() * 1500) )
    },
    "glitchTimeSpan": {
      "start": start,
      "end": start + 0.2
    },
    "shake": {
      "velocity": Math.round( 2 + (Math.random() * 13) ),
      "amplitudeX": 0.05,
      "amplitudeY": 0.05
    },
    "slice": {
      "count": Math.round( 2 + (Math.random() * 4) ),
      "velocity": 15,
      "minHeight": 0.02,
      "maxHeight": 0.1,
      "hueRotate": false
    },
    "pulse": false
  });

  const glitchIcons = useGlitch({
    "playMode": "always",
    "createContainers": true,
    "hideOverflow": false,
    "timing": {
      "duration": Math.round( 5000 + (Math.random() * 1500) )
    },
    "glitchTimeSpan": {
      "start": start,
      "end": start + 0.2
    },
    "shake": {
      "velocity": Math.round( 2 + (Math.random() * 13) ),
      "amplitudeX": 0.15,
      "amplitudeY": 0.15
    },
    "slice": {
      "count": Math.round( 2 + (Math.random() * 4) ),
      "velocity": 15,
      "minHeight": 0.5,
      "maxHeight": 0.1,
      "hueRotate": false
    },
    "pulse": false
  });

  if(Glitchtype ==='full'){
    return(
      <div className="glitchfull">
        <div ref={glitch.ref}>
          {children}
        </div>
      </div>
    );
  } else if(Glitchtype ==='icons'){
    return(
      <div ref={glitchIcons.ref}>
        {children}
      </div>
    );
  } else{
    return(
      <div ref={glitch.ref}>
        {children}
      </div>
    );
  }
};

export default Glitch;