import React, { useState, useEffect } from "react";

function EmojiGenerator(props) {
  const [emojis, setEmojis] = useState([]);
  const { interval, emojisList, className, timeLimit, cap_id } = props;
  const intervalDuration = interval * 300;
  const maxIterations = Math.ceil(timeLimit / interval);
  let count = 0;

  useEffect(() => {
    let iterations = 0;
    const addEmoji = () => {
      const className = `emoji-${Math.floor(Math.random() * 3) + 1}`;
      const right = `${Math.floor(Math.random() * 100)}%`;
      const randomEmoji = emojisList[Math.floor(Math.random() * emojisList.length)];
      count++;
      const newEmoji = (
        <div key={'emoj'+cap_id+count} className={`emoji animation-${className}`} style={{ right: right }}>
          <img src={randomEmoji} alt={count}/>
        </div>
      );
      setEmojis((prevEmojis) => [...prevEmojis, newEmoji]);
      iterations++;
      if (iterations < maxIterations) {
        setTimeout(addEmoji, intervalDuration);
      }
    };
    setTimeout(addEmoji, intervalDuration);
  }, [interval, emojisList, maxIterations, count, cap_id, intervalDuration]);

  return <div className={`emojis ${className}`}>{emojis}</div>;
}

export default EmojiGenerator;
