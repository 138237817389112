import React from "react";
import { useSelector, useDispatch } from 'react-redux'
import { changeShow } from '../stores/share'

import { NavLink } from "react-router-dom";
import Glitch from "./Glitch";

const Share = (params)=>{
  const show = useSelector((state) => state.share.show);
  const dispatch = useDispatch();

  const url = window.location.origin;
  const maintitle = 'Golpeflix | ';

  const handleClose = (e) => {
    if (e.target.id === "wrapper") dispatch(changeShow(false));
  };

  return show ? (
    <div
      className="absolute h-menu inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex  flex-col items-center z-50 justify-center"
      onClick={handleClose}
      id="wrapper"
    >
      <div className="flex flex-col h-menu justify-center items-center bg-grey p-4 rounded-md mx-auto relative">
        <button
          className=" place-self-end absolute top-0 right-0 mt-4"
          onClick={() => {
            dispatch(changeShow(false));
          }}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="1" y="1" width="30" height="30" rx="15" fill="black" />
            <path
              d="M22 10L10 22M10 10L22 22"
              stroke="#F9A51A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              x="1"
              y="1"
              width="30"
              height="30"
              rx="15"
              stroke="#F9A51A"
              strokeWidth="2"
            />
          </svg>
        </button>
        {show?.capitulo && (
          <div className="flex flex-col w-full items-center mb-6  md:mb-2">
            <h2 className="text-center">Compartilhar esse capítulo?</h2>

            <div className="videoarea thumbnail-destaque mb-6">
              {show.img ? (
              <figure className="thumbnail">
                <img
                  className="thumbnail-fit"
                  src={process.env.REACT_APP_CDN + show.img}
                  alt={show.capitulo}
                />
              </figure>
              ) : ''}
              <div className="infos">
                <p className="text-6xl m-0">{show.capitulo}</p>
                <p className=" text-2xl mb-2">{show.titulo}</p>
              </div>
            </div>
            <div className="flex flex-wrap justify-center ">
              <div 
                onClick={() => {navigator.clipboard.writeText(url+'/capitulo/'+show.capitulo)}}
                className="icon cursor-pointer">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.90493 1.56047C10.9856 -0.520155 14.3589 -0.520156 16.4395 1.56047C18.5202 3.64109 18.5202 7.01444 16.4395 9.09507L15.2155 10.3191C14.8249 10.7097 14.1918 10.7097 13.8012 10.3191C13.4107 9.92862 13.4107 9.29545 13.8012 8.90493L15.0253 7.68085C16.3249 6.38128 16.3249 4.27425 15.0253 2.97468C13.7257 1.67511 11.6187 1.67511 10.3191 2.97468L9.09507 4.19876C8.70454 4.58928 8.07138 4.58928 7.68085 4.19876C7.29033 3.80823 7.29033 3.17507 7.68085 2.78454L8.90493 1.56047ZM12.7365 5.26344C13.1271 5.65396 13.1271 6.28713 12.7365 6.67765L6.67767 12.7365C6.28714 13.127 5.65398 13.127 5.26346 12.7365C4.87293 12.346 4.87293 11.7128 5.26346 11.3223L11.3223 5.26344C11.7129 4.87291 12.346 4.87291 12.7365 5.26344ZM4.19876 7.68085C4.58928 8.07138 4.58928 8.70454 4.19876 9.09507L2.97468 10.3191C1.67511 11.6187 1.67511 13.7257 2.97468 15.0253C4.27425 16.3249 6.38128 16.3249 7.68085 15.0253L8.90493 13.8012C9.29546 13.4107 9.92862 13.4107 10.3191 13.8012C10.7097 14.1918 10.7097 14.8249 10.3191 15.2155L9.09507 16.4395C7.01444 18.5202 3.64109 18.5202 1.56047 16.4395C-0.520156 14.3589 -0.520155 10.9856 1.56047 8.90493L2.78454 7.68085C3.17507 7.29033 3.80823 7.29033 4.19876 7.68085Z"
                    fill="black"
                  />
                </svg>
              </div>
              <a
                href={`https://twitter.com/intent/tweet?text=${encodeURI(maintitle+show.titulo)}&url=${encodeURI(url+'/capitulo/'+show.capitulo)}`}
                target="_blank" rel="noreferrer"

                className="icon">
                <svg
                  width="18"
                  height="15"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9648 1.74256C17.2909 2.03925 16.577 2.23509 15.8461 2.32378C16.6156 1.86124 17.1918 1.13587 17.4683 0.281617C16.7551 0.69785 15.9646 1.00084 15.1231 1.16958C14.568 0.575927 13.8323 0.182187 13.0304 0.0495029C12.2286 -0.0831815 11.4053 0.0526145 10.6885 0.435804C9.97172 0.818993 9.4015 1.42813 9.06641 2.16863C8.73132 2.90913 8.65011 3.73955 8.83538 4.53094C5.76801 4.3862 3.04937 2.91326 1.22995 0.6866C0.899058 1.24894 0.726468 1.89032 0.73047 2.54277C0.73047 3.82522 1.38294 4.95242 2.3714 5.61465C1.78557 5.596 1.21268 5.4376 0.700471 5.15267V5.19841C0.700139 6.05067 0.99466 6.8768 1.53407 7.53663C2.07347 8.19647 2.82454 8.64938 3.65985 8.81851C3.11859 8.96354 2.5517 8.98533 2.00092 8.88226C2.2381 9.61568 2.69826 10.2568 3.31722 10.7162C3.93619 11.1756 4.6831 11.4303 5.45377 11.4449C4.14844 12.4693 2.53674 13.0253 0.877463 13.0236C0.584976 13.0236 0.293238 13.0063 0 12.9733C1.69171 14.0565 3.65873 14.6315 5.66751 14.63C12.4577 14.63 16.1663 9.00826 16.1663 4.14096C16.1663 3.98421 16.1663 3.82597 16.1551 3.66848C16.8795 3.14713 17.5044 2.49988 18 1.75756L17.9648 1.74256Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a
                href={`https://api.whatsapp.com/send/?text=${encodeURI(maintitle+show.titulo+' \n\n'+url+'/capitulo/'+show.capitulo)}&type=custom_url&app_absent=0`}
                target="_blank" rel="noreferrer"
                className="icon">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 16.0129L1.14889 11.7449C0.222339 10.0822 -0.0720356 8.1421 0.319785 6.28046C0.711605 4.41882 1.76334 2.76055 3.28199 1.60995C4.80064 0.459358 6.68434 -0.106389 8.58743 0.0165291C10.4905 0.139447 12.2853 0.942786 13.6425 2.27913C14.9997 3.61547 15.8283 5.39519 15.9761 7.29169C16.124 9.18818 15.5812 11.0743 14.4474 12.6038C13.3137 14.1334 11.6649 15.2038 9.80374 15.6187C7.94257 16.0335 5.99384 15.7651 4.31512 14.8624L0 16.0129ZM4.52319 13.2653L4.79006 13.4232C6.00599 14.1421 7.42617 14.4396 8.8293 14.2693C10.2324 14.099 11.5397 13.4706 12.5473 12.4819C13.555 11.4932 14.2064 10.1998 14.4002 8.80319C14.5939 7.40662 14.3191 5.98535 13.6184 4.76086C12.9178 3.53637 11.8308 2.57745 10.5267 2.03354C9.22268 1.48962 7.77488 1.39125 6.40893 1.75377C5.04298 2.11629 3.83563 2.91933 2.97501 4.03775C2.1144 5.15616 1.64887 6.52713 1.65096 7.93701C1.64982 9.10602 1.97395 10.2524 2.58727 11.2486L2.75462 11.5238L2.11233 13.906L4.52319 13.2653Z"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.0003 9.03293C10.8438 8.90707 10.6607 8.81849 10.4647 8.77392C10.2687 8.72935 10.0652 8.72999 9.86948 8.77577C9.57547 8.89758 9.3855 9.35778 9.19552 9.58787C9.15546 9.64303 9.0966 9.68172 9.02998 9.69667C8.96335 9.71162 8.89355 9.70181 8.83367 9.66908C7.7573 9.24857 6.85507 8.47743 6.27354 7.48091C6.22394 7.41873 6.20046 7.33977 6.20806 7.26067C6.21565 7.18156 6.25372 7.10848 6.31425 7.05682C6.52613 6.84759 6.6817 6.58845 6.76657 6.30336C6.78541 5.9889 6.71319 5.67568 6.5585 5.40103C6.43892 5.01598 6.21133 4.67312 5.90264 4.41297C5.74342 4.34154 5.56689 4.31759 5.39433 4.34401C5.22177 4.37043 5.06056 4.44608 4.93015 4.56185C4.70375 4.75669 4.52405 4.99969 4.40428 5.27298C4.28451 5.54626 4.22773 5.84287 4.2381 6.14094C4.2388 6.30833 4.26007 6.475 4.30143 6.63723C4.40646 7.027 4.56798 7.39938 4.78089 7.74259C4.93449 8.0055 5.10209 8.26002 5.28296 8.50507C5.87077 9.30994 6.60966 9.99332 7.45862 10.5173C7.88463 10.7835 8.33994 11 8.81557 11.1624C9.30965 11.3858 9.85516 11.4716 10.3942 11.4106C10.7013 11.3642 10.9923 11.2433 11.2415 11.0584C11.4907 10.8735 11.6906 10.6304 11.8235 10.3503C11.9016 10.1812 11.9253 9.99207 11.8913 9.80894C11.8099 9.43447 11.3079 9.2134 11.0003 9.03293Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a
                href={`http://www.facebook.com/sharer.php?u=${encodeURI(url+'/capitulo/'+show.capitulo)}&t=${encodeURI(maintitle+show.titulo)}`}
                target="_blank" rel="noreferrer"
                className="icon">
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.1094 9.05546C18.1094 4.05383 14.0556 0 9.05471 0C4.05383 0 0 4.05383 0 9.05546C0 13.5741 3.31128 17.3201 7.63979 17.9992V11.6725H5.34121V9.05471H7.63979V7.06024C7.63979 4.79034 8.99208 3.53691 11.0597 3.53691C12.0506 3.53691 13.0867 3.7135 13.0867 3.7135V5.94189H11.9457C10.8213 5.94189 10.4704 6.63992 10.4704 7.35606V9.05546H12.9818L12.5803 11.6732H10.4704V18C14.7989 17.3208 18.1102 13.5749 18.1102 9.05546H18.1094Z"
                    fill="black"
                  />
                </svg>
              </a>
            </div>
          </div>
        )}

        <div className="flex flex-col w-full items-center  mb-6 md:mb-2">
          <h2 className="mb-6 text-center">Compartilhar o projeto</h2>

          <Glitch>
            <NavLink key="home" to="/home" id="logo">
              <svg
                width="287"
                height="40"
                viewBox="0 0 287 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.8757 40C15.3649 40 12.1696 39.2505 9.28994 37.7515C6.4497 36.2525 4.18146 34.0434 2.48521 31.1243C0.828402 28.1657 0 24.5365 0 20.2367C0 15.9763 0.848125 12.3471 2.54438 9.34911C4.28008 6.31164 6.56805 4.00395 9.40829 2.42604C12.2485 0.808679 15.3846 0 18.8166 0C21.6174 0 24.0237 0.512821 26.0355 1.53846C28.0473 2.5641 29.6844 3.72781 30.9467 5.02958L26.3905 10.5917C25.4043 9.72387 24.359 8.99408 23.2544 8.40237C22.1499 7.81065 20.7692 7.51479 19.1124 7.51479C16.1144 7.51479 13.6686 8.61933 11.7751 10.8284C9.88166 13.0375 8.93491 16.075 8.93491 19.9408C8.93491 23.8856 9.84221 26.9625 11.6568 29.1716C13.4714 31.3807 16.1736 32.4852 19.7633 32.4852C20.5523 32.4852 21.3215 32.3866 22.071 32.1893C22.8205 31.9921 23.432 31.6963 23.9053 31.3018V24.6746H17.5148V17.574H31.5976V35.3254C30.2564 36.6272 28.4615 37.7318 26.213 38.6391C24.0039 39.5464 21.5582 40 18.8757 40Z"
                  fill="#F9A51A"
                />
                <path
                  d="M55.2482 40C51.7768 40 48.7196 39.1913 46.0766 37.574C43.4731 35.9566 41.4218 33.6489 39.9228 30.6509C38.4632 27.6134 37.7334 24.0039 37.7334 19.8225C37.7334 15.6016 38.4632 12.0316 39.9228 9.11242C41.4218 6.15385 43.4731 3.90533 46.0766 2.36686C48.7196 0.788955 51.7768 0 55.2482 0C58.7196 0 61.7571 0.788955 64.3607 2.36686C67.0037 3.90533 69.0549 6.15385 70.5145 9.11242C72.0135 12.071 72.763 15.641 72.763 19.8225C72.763 24.0039 72.0135 27.6134 70.5145 30.6509C69.0549 33.6489 67.0037 35.9566 64.3607 37.574C61.7571 39.1913 58.7196 40 55.2482 40ZM55.2482 32.4852C57.8912 32.4852 59.982 31.3412 61.5204 29.0533C63.0589 26.7653 63.8281 23.6884 63.8281 19.8225C63.8281 15.9566 63.0589 12.9389 61.5204 10.7692C59.982 8.59961 57.8912 7.51479 55.2482 7.51479C52.6052 7.51479 50.5145 8.59961 48.9761 10.7692C47.4376 12.9389 46.6684 15.9566 46.6684 19.8225C46.6684 23.6884 47.4376 26.7653 48.9761 29.0533C50.5145 31.3412 52.6052 32.4852 55.2482 32.4852Z"
                  fill="#F9A51A"
                />
                <path
                  d="M80.0171 39.2899V0.710061H88.7153V31.9527H103.982V39.2899H80.0171Z"
                  fill="#F9A51A"
                />
                <path
                  d="M110.643 39.2899V0.710061H123.897C126.738 0.710061 129.302 1.10454 131.59 1.89349C133.917 2.643 135.771 3.92505 137.152 5.73964C138.533 7.55424 139.223 10.0197 139.223 13.1361C139.223 16.1341 138.533 18.5996 137.152 20.5325C135.771 22.4655 133.937 23.9053 131.649 24.8521C129.361 25.7594 126.856 26.213 124.134 26.213H119.341V39.2899H110.643ZM119.341 19.2899H123.602C128.335 19.2899 130.702 17.2387 130.702 13.1361C130.702 11.1243 130.071 9.70414 128.809 8.87574C127.546 8.04734 125.732 7.63314 123.365 7.63314H119.341V19.2899Z"
                  fill="#F9A51A"
                />
                <path
                  d="M145.892 39.2899V0.710061H170.034V8.04734H154.59V15.7988H167.726V23.0769H154.59V31.9527H170.625V39.2899H145.892Z"
                  fill="#F9A51A"
                />
                <path
                  d="M178.309 39.2899V0.710061H202.629V8.04734H187.007V16.9823H200.38V24.3195H187.007V39.2899H178.309Z"
                  fill="#F9A51A"
                />
                <path
                  d="M209.339 39.2899V0.710061H218.038V31.9527H233.304V39.2899H209.339Z"
                  fill="#F9A51A"
                />
                <path
                  d="M239.965 39.2899V0.710061H248.664V39.2899H239.965Z"
                  fill="#F9A51A"
                />
                <path
                  d="M253.858 39.2899L264.568 19.4675L254.509 0.710061H264.213L267.586 7.98816C268.019 8.85602 268.453 9.80276 268.887 10.8284C269.361 11.854 269.854 12.9783 270.367 14.2012H270.603C271.037 12.9783 271.451 11.854 271.846 10.8284C272.28 9.80276 272.694 8.85602 273.088 7.98816L276.165 0.710061H285.455L275.455 19.8817L286.106 39.2899H276.402L272.556 31.4201C272.083 30.3945 271.609 29.3886 271.136 28.4024C270.702 27.3767 270.209 26.2722 269.657 25.0888H269.42C268.986 26.2722 268.552 27.3767 268.118 28.4024C267.684 29.3886 267.25 30.3945 266.816 31.4201L263.207 39.2899H253.858Z"
                  fill="#F9A51A"
                />
              </svg>
            </NavLink>
          </Glitch>

          <div className="flex flex-wrap justify-center  mt-6">
            <div 
              onClick={() => {navigator.clipboard.writeText(url)}}
              className="icon cursor-pointer">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.90493 1.56047C10.9856 -0.520155 14.3589 -0.520156 16.4395 1.56047C18.5202 3.64109 18.5202 7.01444 16.4395 9.09507L15.2155 10.3191C14.8249 10.7097 14.1918 10.7097 13.8012 10.3191C13.4107 9.92862 13.4107 9.29545 13.8012 8.90493L15.0253 7.68085C16.3249 6.38128 16.3249 4.27425 15.0253 2.97468C13.7257 1.67511 11.6187 1.67511 10.3191 2.97468L9.09507 4.19876C8.70454 4.58928 8.07138 4.58928 7.68085 4.19876C7.29033 3.80823 7.29033 3.17507 7.68085 2.78454L8.90493 1.56047ZM12.7365 5.26344C13.1271 5.65396 13.1271 6.28713 12.7365 6.67765L6.67767 12.7365C6.28714 13.127 5.65398 13.127 5.26346 12.7365C4.87293 12.346 4.87293 11.7128 5.26346 11.3223L11.3223 5.26344C11.7129 4.87291 12.346 4.87291 12.7365 5.26344ZM4.19876 7.68085C4.58928 8.07138 4.58928 8.70454 4.19876 9.09507L2.97468 10.3191C1.67511 11.6187 1.67511 13.7257 2.97468 15.0253C4.27425 16.3249 6.38128 16.3249 7.68085 15.0253L8.90493 13.8012C9.29546 13.4107 9.92862 13.4107 10.3191 13.8012C10.7097 14.1918 10.7097 14.8249 10.3191 15.2155L9.09507 16.4395C7.01444 18.5202 3.64109 18.5202 1.56047 16.4395C-0.520156 14.3589 -0.520155 10.9856 1.56047 8.90493L2.78454 7.68085C3.17507 7.29033 3.80823 7.29033 4.19876 7.68085Z"
                  fill="black"
                />
              </svg>
            </div>
            <a
              href={`https://twitter.com/intent/tweet?text=${encodeURI(maintitle+'AosFatos')}&url=${encodeURI(url)}`}
              target="_blank" rel="noreferrer"

              className="icon">
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.9648 1.74256C17.2909 2.03925 16.577 2.23509 15.8461 2.32378C16.6156 1.86124 17.1918 1.13587 17.4683 0.281617C16.7551 0.69785 15.9646 1.00084 15.1231 1.16958C14.568 0.575927 13.8323 0.182187 13.0304 0.0495029C12.2286 -0.0831815 11.4053 0.0526145 10.6885 0.435804C9.97172 0.818993 9.4015 1.42813 9.06641 2.16863C8.73132 2.90913 8.65011 3.73955 8.83538 4.53094C5.76801 4.3862 3.04937 2.91326 1.22995 0.6866C0.899058 1.24894 0.726468 1.89032 0.73047 2.54277C0.73047 3.82522 1.38294 4.95242 2.3714 5.61465C1.78557 5.596 1.21268 5.4376 0.700471 5.15267V5.19841C0.700139 6.05067 0.99466 6.8768 1.53407 7.53663C2.07347 8.19647 2.82454 8.64938 3.65985 8.81851C3.11859 8.96354 2.5517 8.98533 2.00092 8.88226C2.2381 9.61568 2.69826 10.2568 3.31722 10.7162C3.93619 11.1756 4.6831 11.4303 5.45377 11.4449C4.14844 12.4693 2.53674 13.0253 0.877463 13.0236C0.584976 13.0236 0.293238 13.0063 0 12.9733C1.69171 14.0565 3.65873 14.6315 5.66751 14.63C12.4577 14.63 16.1663 9.00826 16.1663 4.14096C16.1663 3.98421 16.1663 3.82597 16.1551 3.66848C16.8795 3.14713 17.5044 2.49988 18 1.75756L17.9648 1.74256Z"
                  fill="black"
                />
              </svg>
            </a>
            <a
              href={`https://api.whatsapp.com/send/?text=${encodeURI(maintitle+'AosFatos \n\n'+url)}&type=custom_url&app_absent=0`}
              target="_blank" rel="noreferrer"
              className="icon">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 16.0129L1.14889 11.7449C0.222339 10.0822 -0.0720356 8.1421 0.319785 6.28046C0.711605 4.41882 1.76334 2.76055 3.28199 1.60995C4.80064 0.459358 6.68434 -0.106389 8.58743 0.0165291C10.4905 0.139447 12.2853 0.942786 13.6425 2.27913C14.9997 3.61547 15.8283 5.39519 15.9761 7.29169C16.124 9.18818 15.5812 11.0743 14.4474 12.6038C13.3137 14.1334 11.6649 15.2038 9.80374 15.6187C7.94257 16.0335 5.99384 15.7651 4.31512 14.8624L0 16.0129ZM4.52319 13.2653L4.79006 13.4232C6.00599 14.1421 7.42617 14.4396 8.8293 14.2693C10.2324 14.099 11.5397 13.4706 12.5473 12.4819C13.555 11.4932 14.2064 10.1998 14.4002 8.80319C14.5939 7.40662 14.3191 5.98535 13.6184 4.76086C12.9178 3.53637 11.8308 2.57745 10.5267 2.03354C9.22268 1.48962 7.77488 1.39125 6.40893 1.75377C5.04298 2.11629 3.83563 2.91933 2.97501 4.03775C2.1144 5.15616 1.64887 6.52713 1.65096 7.93701C1.64982 9.10602 1.97395 10.2524 2.58727 11.2486L2.75462 11.5238L2.11233 13.906L4.52319 13.2653Z"
                  fill="black"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.0003 9.03293C10.8438 8.90707 10.6607 8.81849 10.4647 8.77392C10.2687 8.72935 10.0652 8.72999 9.86948 8.77577C9.57547 8.89758 9.3855 9.35778 9.19552 9.58787C9.15546 9.64303 9.0966 9.68172 9.02998 9.69667C8.96335 9.71162 8.89355 9.70181 8.83367 9.66908C7.7573 9.24857 6.85507 8.47743 6.27354 7.48091C6.22394 7.41873 6.20046 7.33977 6.20806 7.26067C6.21565 7.18156 6.25372 7.10848 6.31425 7.05682C6.52613 6.84759 6.6817 6.58845 6.76657 6.30336C6.78541 5.9889 6.71319 5.67568 6.5585 5.40103C6.43892 5.01598 6.21133 4.67312 5.90264 4.41297C5.74342 4.34154 5.56689 4.31759 5.39433 4.34401C5.22177 4.37043 5.06056 4.44608 4.93015 4.56185C4.70375 4.75669 4.52405 4.99969 4.40428 5.27298C4.28451 5.54626 4.22773 5.84287 4.2381 6.14094C4.2388 6.30833 4.26007 6.475 4.30143 6.63723C4.40646 7.027 4.56798 7.39938 4.78089 7.74259C4.93449 8.0055 5.10209 8.26002 5.28296 8.50507C5.87077 9.30994 6.60966 9.99332 7.45862 10.5173C7.88463 10.7835 8.33994 11 8.81557 11.1624C9.30965 11.3858 9.85516 11.4716 10.3942 11.4106C10.7013 11.3642 10.9923 11.2433 11.2415 11.0584C11.4907 10.8735 11.6906 10.6304 11.8235 10.3503C11.9016 10.1812 11.9253 9.99207 11.8913 9.80894C11.8099 9.43447 11.3079 9.2134 11.0003 9.03293Z"
                  fill="black"
                />
              </svg>
            </a>
            <a
              href={`http://www.facebook.com/sharer.php?u=${encodeURI(url)}&t=${encodeURI(maintitle+'AosFatos')}`}
              target="_blank" rel="noreferrer"
              className="icon">
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.1094 9.05546C18.1094 4.05383 14.0556 0 9.05471 0C4.05383 0 0 4.05383 0 9.05546C0 13.5741 3.31128 17.3201 7.63979 17.9992V11.6725H5.34121V9.05471H7.63979V7.06024C7.63979 4.79034 8.99208 3.53691 11.0597 3.53691C12.0506 3.53691 13.0867 3.7135 13.0867 3.7135V5.94189H11.9457C10.8213 5.94189 10.4704 6.63992 10.4704 7.35606V9.05546H12.9818L12.5803 11.6732H10.4704V18C14.7989 17.3208 18.1102 13.5749 18.1102 9.05546H18.1094Z"
                  fill="black"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : '';
}

export const ShareButton = (params)=>{
  const dispatch = useDispatch();

  const setShowShare = ()=>{
    dispatch(changeShow(params.capitulo));
  };

  return (
    <button id="shareBtn"
      onClick={setShowShare}
    >
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7748 35.6871L12.1692 33.3687C11.2092 32.1493 9.904 31.3607 8.39435 31.088L6.22553 30.6912C5.17214 30.4997 4.28661 29.7538 3.91466 28.7446C3.72827 28.2414 3.68154 27.6913 3.77972 27.1537L4.16826 25.0281C4.45383 23.443 4.13169 21.8334 3.26103 20.4959C3.18852 20.3789 3.10888 20.2674 3.0404 20.1734L1.5387 18.0068C1.17538 17.4781 0.989372 16.8613 1.00047 16.2229C1.01901 15.2445 1.50863 14.3301 2.3103 13.7766L4.82213 12.0383C6.06077 11.1174 6.91484 9.76895 7.22368 8.24407L7.63183 6.00248C7.85548 4.7741 8.79216 3.81782 10.0181 3.5664C10.3911 3.48866 10.7842 3.48431 11.1681 3.55398L13.6477 4.00633C14.3184 4.09075 14.9973 4.06477 15.6555 3.92935C16.4836 3.76272 17.2648 3.42852 17.978 2.936L19.9913 1.54295C20.3333 1.30653 20.711 1.14469 21.1142 1.06185C21.3153 1.0208 21.5207 1 21.7249 1C22.7209 1 23.6562 1.49131 24.2265 2.31431L25.7373 4.49803C26.6658 5.74538 28.0187 6.60016 29.5444 6.90145L32.3235 7.4079C33.1238 7.55592 33.8184 8.00342 34.2799 8.66792C34.7428 9.33823 34.9167 10.1464 34.7699 10.9439L34.2417 13.8549C34.0214 15.3984 34.3813 16.9628 35.2559 18.2551L36.4598 19.9929C36.9211 20.6605 37.0952 21.4685 36.9501 22.2681C36.8049 23.0684 36.3573 23.763 35.6898 24.2237L33.7259 25.5833C32.5053 26.5373 31.6827 27.8955 31.4093 29.4084L30.9191 32.0944C30.6935 33.3281 29.7571 34.2848 28.5335 34.5317C28.1654 34.6097 27.7752 34.6143 27.3811 34.5448L24.4929 34.0176C24.1967 33.9743 23.8908 33.9519 23.5882 33.9519C23.1695 33.9519 22.7466 33.9946 22.3313 34.0788C21.5136 34.2448 20.7461 34.57 20.0501 35.0451L18.0071 36.4574C17.6718 36.69 17.2953 36.852 16.888 36.9388C16.6873 36.9795 16.4833 37 16.2804 37C15.28 37 14.3433 36.5092 13.7748 35.6871Z" fill="#F9A51A" stroke="#F9A51A" stroke-width="2"/>
      <path d="M13.9689 20.3424L20.04 23.8802M20.0311 14.1202L13.9689 17.658M25 12.778C25 14.2508 23.8061 15.4447 22.3333 15.4447C20.8606 15.4447 19.6667 14.2508 19.6667 12.778C19.6667 11.3052 20.8606 10.1113 22.3333 10.1113C23.8061 10.1113 25 11.3052 25 12.778ZM14.3333 19.0002C14.3333 20.473 13.1394 21.6669 11.6667 21.6669C10.1939 21.6669 9 20.473 9 19.0002C9 17.5275 10.1939 16.3336 11.6667 16.3336C13.1394 16.3336 14.3333 17.5275 14.3333 19.0002ZM25 25.2224C25 26.6952 23.8061 27.8891 22.3333 27.8891C20.8606 27.8891 19.6667 26.6952 19.6667 25.2224C19.6667 23.7497 20.8606 22.5558 22.3333 22.5558C23.8061 22.5558 25 23.7497 25 25.2224Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  );
}

export default Share;
