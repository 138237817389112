import { Routes, Route, Navigate  } from "react-router-dom";

import CapaPage from "../pages/CapaPage";
import IntroPage from "../pages/IntroPage";
import IndicePage from "../pages/IndicePage";
import CapituloPage from "../pages/CapituloPage";
import SubCapituloPage from "../pages/SubCapituloPage";

/* ErrorPage */
import ErrorPage from "../pages/ErrorPage";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<CapaPage />} />
      <Route path="/intro" element={<IntroPage />} />

      <Route path="/indice" element={<IndicePage />} />
      <Route path="/capitulo" element={<Navigate  to="/indice"/>} />
      <Route path="/capitulo/:cap_id" element={<CapituloPage />} />
      <Route path="/capitulo/:cap_id/:sub_id" element={<SubCapituloPage />} />

      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default AppRoutes;
