import NavBar from "./components/NavBar";
import AppRoutes from "./routes/app.routes";
import { Toaster } from "react-hot-toast";
import Share from "./components/Share";

function App() {
return (
    <div className="app">
      <div className="relative w-full max-w-xl mx-auto flex flex-col h-menu">
        {/* <NavBar />
        para habilitar o menu: incluir pt-3rem min-h-screen; retirar h-menu */}
        <AppRoutes />
        <Share />
      </div>
      <Toaster />
    </div>
  );
}

export default App;
